var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "b-row",
        { staticClass: "m-0 pt-1" },
        [
          _c("b-col", { staticClass: "pr-0 pl-0 d-flex align-items-center" }, [
            _c("div", { staticClass: "d-flex w-100" }, [
              _c(
                "div",
                {
                  ref: "navToggle",
                  staticClass: "nav-toggle nav-toggle-left py-2 mr-3 my-auto",
                  on: { click: _vm.toggleNavMobile },
                },
                [
                  _c("fa-icon", {
                    staticClass: "toggle-button m-auto",
                    attrs: { icon: "bars", size: "lg" },
                  }),
                ],
                1
              ),
              _c("h1", { staticClass: "title py-2 pr-2 pl-3 my-auto" }, [
                _vm._v("API Management"),
              ]),
              _c(
                "div",
                {
                  ref: "buttonToggle",
                  staticClass: "nav-toggle d-flex my-auto ml-auto",
                  on: { click: _vm.toggleShowButtonsOnMobile },
                },
                [
                  _c("fa-icon", {
                    staticClass: "toggle-button m-auto",
                    attrs: { icon: "ellipsis-v", size: "lg" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex flex-wrap align-items-center justify-content-end",
              attrs: { cols: "12", md: "auto" },
            },
            [
              _vm.selectedTab === 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end flex-wrap w-auto py-2",
                      class: _vm.mobileButtonClass,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex w-auto py-2",
                          class: _vm.mobileButtonClass,
                        },
                        [
                          _c(
                            "b-button",
                            {
                              class: [
                                _vm.loading
                                  ? "disabled-button-grey"
                                  : "primary-button",
                                "ml-1",
                                "mr-0",
                              ],
                              staticStyle: { "margin-right": "2.5em" },
                              attrs: {
                                variant: "primary",
                                "aria-label": "Create API Key button",
                                disabled: _vm.hasMaximumKeys || _vm.loading,
                              },
                              on: { click: _vm.createAccessKey },
                            },
                            [_vm._v("\n            Create API Key\n          ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "m-0" }),
      _c("div", { staticClass: "content-body" }, [
        _c("div", { staticClass: "side-nav", class: _vm.navClass }, [
          _c(
            "button",
            {
              staticClass: "nav-button",
              class: _vm.selectedTab === 0 ? "selected" : null,
              attrs: { "aria-label": "API Keys button" },
              on: {
                click: function ($event) {
                  return _vm.tabChange(0)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-items-center text-center m-auto pa-2",
                },
                [
                  _c("fa-icon", {
                    staticClass: "mt-1",
                    attrs: { icon: "key", size: "lg" },
                  }),
                  _c("span", { staticClass: "mt-1" }, [_vm._v("API Keys")]),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "scrollable-content" },
            [
              !_vm.loading
                ? _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === 0,
                            expression: "selectedTab === 0",
                          },
                        ],
                      },
                      [
                        _vm.getAll?.length > 0
                          ? _c("ct-table", {
                              attrs: {
                                "is-paginated": false,
                                fields: _vm.accessKeysDefinition.columns,
                                items: _vm.getAll,
                                loaded: !_vm.loading,
                                "table-definition": _vm.accessKeysDefinition,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "cell(description)",
                                    fn: function (row) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.item.description) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "cell(enabled)",
                                    fn: function (data) {
                                      return [
                                        !data.item.deletedAt
                                          ? _c("fa-icon", {
                                              attrs: { icon: "check" },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "cell(accessKey)",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "word-break": "break-all",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(data.item.accessKey) +
                                                "\n                  "
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "link",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copyToClipboard(
                                                      data.item.accessKey,
                                                      null,
                                                      _vm.successToast,
                                                      _vm.errorToast
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("copy-icon")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "cell(actions)",
                                    fn: function (row) {
                                      return [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mr-1 pt-0",
                                            attrs: { variant: "link" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUpdateModal(
                                                  row.item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("fa-icon", {
                                              attrs: { icon: "edit" },
                                            }),
                                            _vm._v(
                                              "\n                  Edit\n                "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "danger",
                                            attrs: { variant: "link" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$bvModal.show(
                                                  `confirm-delete-key-${row.item.id}`
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("fa-icon", {
                                              attrs: { icon: "trash" },
                                            }),
                                            _vm._v(
                                              "\n                  Delete\n                "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "confirmation-modal",
                                          {
                                            attrs: {
                                              id: `confirm-delete-key-${row.item.id}`,
                                              title: "Delete API Key?",
                                              "ok-button-text": "Delete",
                                              "cancel-button-text": "Cancel",
                                            },
                                            on: {
                                              ok: function ($event) {
                                                return _vm.destroyAccessKey(
                                                  row.item.id
                                                )
                                              },
                                              cancel: function ($event) {
                                                return _vm.$bvModal.hide(
                                                  `confirm-delete-key-${row.item.id}`
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v("This cannot be undone."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2094411118
                              ),
                            })
                          : _vm._e(),
                        !_vm.allKeys?.length
                          ? _c("div", { staticClass: "text-center" }, [
                              _c(
                                "div",
                                { staticClass: "empty-state" },
                                [
                                  _c("h2", [_vm._v("No API keys")]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                  Click the button below to create your first API key and get started.\n                "
                                    ),
                                  ]),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "mr-1 border border-primary text-primary secondary-button",
                                      attrs: {
                                        href: _vm.learnMoreURL,
                                        variant: "link",
                                        "aria-label": "Learn More button",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Learn More\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: [
                                        _vm.loading
                                          ? "disabled-button-grey"
                                          : "primary-button",
                                        "ml-1",
                                      ],
                                      attrs: {
                                        variant: "primary",
                                        disabled: _vm.loading,
                                        "aria-label": "Create API Key button",
                                      },
                                      on: { click: _vm.createAccessKey },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Create API Key\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _c("ct-centered-spinner"),
            ],
            1
          ),
        ]),
      ]),
      _c("account-access-key-modal", { attrs: { bus: _vm.bus } }),
      _c("learn-more-button", {
        attrs: { tabindex: "0" },
        on: {
          onclick: _vm.learnMore,
          onKeyDown: function ($event) {
            return _vm.learnMore()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }