<template>
  <div class="tabbed-page">
    <b-row class="m-0 pt-1">
      <b-col class="pr-0 pl-0 d-flex align-items-center">
        <div class="d-flex w-100">
          <div
            ref="navToggle"
            class="nav-toggle nav-toggle-left py-2 mr-3 my-auto"
            @click="toggleNavMobile"
          >
            <fa-icon class="toggle-button m-auto" icon="bars" size="lg" />
          </div>
          <h1 class="title py-2 pr-2 pl-3 my-auto">API Management</h1>
          <div
            ref="buttonToggle"
            class="nav-toggle d-flex my-auto ml-auto"
            @click="toggleShowButtonsOnMobile"
          >
            <fa-icon class="toggle-button m-auto" icon="ellipsis-v" size="lg" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="auto" class="d-flex flex-wrap align-items-center justify-content-end">
        <div
          v-if="selectedTab === 0"
          class="d-flex align-items-center justify-content-end flex-wrap w-auto py-2"
          :class="mobileButtonClass"
        >
          <div class="d-flex w-auto py-2" :class="mobileButtonClass">
            <b-button
              :class="[
                loading ? 'disabled-button-grey' : 'primary-button',
                'ml-1',
                'mr-0'
              ]"
              style="margin-right: 2.5em"
              variant="primary"
              aria-label="Create API Key button"
              :disabled="hasMaximumKeys || loading"
              @click="createAccessKey"
            >
              Create API Key
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="m-0">
    <div class="content-body">
      <div class="side-nav" :class="navClass">
        <button
          class="nav-button"
          :class="selectedTab === 0 ? 'selected' : null"
          aria-label="API Keys button"
          @click="tabChange(0)"
        >
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="key" size="lg" />
            <span class="mt-1">API Keys</span>
          </div>
        </button>
      </div>
      <div class="content">
        <div class="scrollable-content">
          <div v-if="!loading">
            <div v-show="selectedTab === 0">
              <ct-table
                v-if="getAll?.length > 0"
                :is-paginated="false"
                :fields="accessKeysDefinition.columns"
                :items="getAll"
                :loaded="!loading"
                :table-definition="accessKeysDefinition"
              >
                <template slot="cell(description)" slot-scope="row">
                  {{ row.item.description }}
                </template>
                <template slot="cell(enabled)" slot-scope="data">
                  <fa-icon v-if="!data.item.deletedAt" icon="check" />
                </template>
                <template slot="cell(accessKey)" slot-scope="data">
                  <p style="word-break: break-all">
                    {{ data.item.accessKey }}
                    <b-button
                      variant="link"
                      size="sm"
                      @click="copyToClipboard(data.item.accessKey, null, successToast, errorToast)"
                    >
                      <copy-icon />
                    </b-button>
                  </p>
                </template>
                <template slot="cell(actions)" slot-scope="row">
                  <b-button
                    variant="link"
                    class="mr-1 pt-0"
                    @click="openUpdateModal(row.item)"
                  >
                    <fa-icon icon="edit" />
                    Edit
                  </b-button>
                  <b-button
                    variant="link"
                    class="danger"
                    @click="$bvModal.show(`confirm-delete-key-${row.item.id}`)"
                  >
                    <fa-icon icon="trash" />
                    Delete
                  </b-button>
                  <confirmation-modal
                    :id="`confirm-delete-key-${row.item.id}`"
                    :title="'Delete API Key?'"
                    :ok-button-text="'Delete'"
                    :cancel-button-text="'Cancel'"
                    @ok="destroyAccessKey(row.item.id)"
                    @cancel="$bvModal.hide(`confirm-delete-key-${row.item.id}`)"
                  >
                    <div>This cannot be undone.</div>
                  </confirmation-modal>
                </template>
              </ct-table>
              <div v-if="!allKeys?.length" class="text-center">
                <div class="empty-state">
                  <h2>No API keys</h2>
                  <p>
                    Click the button below to create your first API key and get started.
                  </p>
                  <b-button
                    :href="learnMoreURL"
                    class="mr-1 border border-primary text-primary secondary-button"
                    variant="link"
                    aria-label="Learn More button"
                  >
                    Learn More
                  </b-button>
                  <b-button
                    :class="[
                      loading ? 'disabled-button-grey' : 'primary-button',
                      'ml-1',
                    ]"
                    variant="primary"
                    :disabled="loading"
                    aria-label="Create API Key button"
                    @click="createAccessKey"
                  >
                    Create API Key
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <ct-centered-spinner v-else />
        </div>
      </div>
    </div>
    <account-access-key-modal :bus="bus" />
    <learn-more-button tabindex="0" @onclick="learnMore" @onKeyDown="learnMore()" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { formatDateString } from '@/common/modules/formatters'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'

export default {
  components: {
    AccountAccessKeyModal: () => import('@/components/APIManagement/AccountAccessKeyModal.vue'),
    ConfirmationModal: () => import('@/components/ConfirmationModal.vue'),
    CopyIcon: () => import('@images/ui/copy-icon.svg'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    CtTable: () => import('@/components/shared/CtTable.vue'),
    LearnMoreButton: () => import('@/components/shared/LearnMoreButton.vue'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  data() {
    return {
      selectedTab: 0,
      accountAccessKeys: [],
      loading: true,
      showButtonsOnMobile: false,
      showNavMobile: false,
      learnMoreURL: 'https://docs.corporatetools.com/',
      bus: new Vue(),
      query: null,
      appliedFilters: [],
      maxApiKeys: 3,
    }
  },
  computed: {
    ...mapGetters('accountAccessKey', ['getAll']),
    ...mapGetters('account', ['actualAccountID']),
    hasMaximumKeys() {
      return this.accountAccessKeys.length >= 3
    },
    mobileButtonClass() {
      return this.showButtonsOnMobile ? 'display-buttons' : 'hide-buttons'
    },
    navClass() {
      return this.showNavMobile ? null : 'hidden'
    },
    allKeys() {
      return (this.getAll || []).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
    },
    accessKeysDefinition() {
      return {
        columns: [
          { key: 'description' },
          { key: 'enabled' },
          { key: 'accessKey' },
          { key: 'createdAt', formatter: value => formatDateString(value) },
          { key: 'actions', selectable: true },
        ],
      }
    },
  },
  async mounted() {
    try {
      await this.fetchAll()
    } catch (error) {
      this.errorToast('Error', 'Failed to fetch api keys')
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('accountAccessKey', ['fetchAll', 'destroy', 'update']),
    createAccessKey() {
      if (this.getAll?.length >= this.maxApiKeys) {
        this.errorToast(`Error`, `You can only create ${this.maxApiKeys} API keys`)
      } else {
        this.bus.$emit('show-create-account-access-key')
      }
    },

    openUpdateModal(key) {
      this.bus.$emit('show-edit-account-access-key', key)
    },

    async destroyAccessKey(id) {
      try {
        await this.destroy({ id })
      } catch (err) {
        console.error(err)
        this.errorToast("Error", "Failed to delete api key")
      }
    },

    tabChange(tabNumber) {
      this.selectedTab = tabNumber
    },

    toggleNavMobile() {
      this.showNavMobile = !this.showNavMobile
    },

    toggleShowButtonsOnMobile() {
      this.showButtonsOnMobile = !this.showButtonsOnMobile
    },

    learnMore(event) {
      // Check if Ctrl (Windows/Linux) or Command (Mac) key is held or if middle mouse button is clicked
      const isCtrlOrCmd = event?.ctrlKey || event?.metaKey
      const isMiddleClick = event?.button === 1
      if (isCtrlOrCmd || isMiddleClick) {
        window.open(this.learnMoreURL, '_blank')
      } else {
        window.location.href = this.learnMoreURL
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// palette
$violet2: #000864;

.tabbed-page {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: -20px -15px;
  display: flex;
  flex-direction: column;

  .hover-pointer {
    :hover {
      cursor: pointer;
    }
  }

  .nav-toggle {
    display: none;

    * {
      display: none;
    }
  }

  .primary-button {
    background-color: $violet2;
    border-color: $violet2 !important;
    color: white;
    border-radius: 4px;

    &:not(:disabled):hover {
      background-color: #3F458A !important;
    }

    &-outlined {
      color: $violet2 !important;
      border-color: $violet2 !important;
      border-radius: 4px !important;
      background-color: white !important;
      height: 40px;

      &:not(:disabled):hover {
        background-color: #ECECFA !important;
      }
    }
  }

  .secondary-button {
    border-radius: 4px;
  }

  .danger {
    color: #BC2F2F !important;
  }

  button {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .disabled-button-grey {
    background-color: #e8e4e4;
    border-color: transparent;
    border-radius: 4px;
    color: #939292 !important;
    cursor: not-allowed;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  hr {
    border-top: 1px solid #BDBDBD;
    margin: 0;
  }

  .content-body {
    flex: 1;
    display: flex;

    .side-nav {
      background-color: #F8F8F8;
      min-height: 100%;
      min-width: 125px;
      width: 125px;
      margin-bottom: .25rem;

      .nav-button {
        display: flex;
        width: 100%;
        height: 100px;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;

        .shared {
          padding: 0 1.5rem;
        }

        &:hover {
          cursor: pointer;
        }

        &.selected {
          background-color: #EAE9FC;
        }
      }

      @media (min-width: 768px) {
        * {
          display: flex !important;
        }
      }
    }

    .scrollable-content {
      flex: 1;
      overflow-y: auto;
      height: 80vh;
      padding: 15px;
    }

    .content {
      min-height: 100%;
      flex: 1;
    }
  }

  @media only screen and (max-width: 767px) {
    .primary-button {
      flex-grow: 1;
      height: auto;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .nav-toggle {
      display: flex;
      border: 1px solid #BDBDBD;
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      width: 50px;
      height: 50px;

      * {
        display: block;
      }
    }

    .nav-toggle-left {
      border-radius: 0 8px 8px 0;
    }

    .d-flex {
      flex-direction: row !important;
    }

    .display-buttons {
      margin: 0 auto !important;
    }

    .hide-buttons {
      display: none !important;

      * {
        display: none !important;
      }
    }

    .content-body {
      flex-direction: column;

      .side-nav {
        min-height: 0;
        width: 100%;
        display: flex;
        flex-direction: row;

        .nav-button {
          flex: 1;
          height: 75px;
          padding: 0;

          svg {
            margin-right: 0.5rem;
          }
        }

        &.hidden {
          display: none;

          * {
            display: none;
          }
        }
      }

      .content {
        min-height: 100%;
        flex: 1;
      }
    }
  }
}

</style>
